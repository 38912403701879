<template>
      <div class="sampleReels flex-column">
            <div class="Header flex-align-between">
                  <div class="entrance">
                        <div class="head flex-align">
                              <img :src="require('../../assets/homepage/tou.png')">
                        </div>
                        <div class="type">
                              <ul class="flex-between">
                                    <li class="flex-column-center" v-for="(item,index) in tackclass" :key="index">
                                          <img :src="item.imgs" @click="site(item.name)">
                                          <span @click="site(item.name)">{{item.name}}</span>
                                    </li>
                              </ul>
                        </div>
                  </div>
                  <div class="back flex-align-between">
                        <img :src="require('../../assets/task/return.png')" @click="goback">
                        <span @click="goback">返回</span>
                  </div>
            </div>
            <div class="Main flex-align-center">
                  <div class="workBox">
                        <Sample :category='category'></Sample>
                  </div>
            </div>
      </div>
</template>
<script>
      import Sample from '../../views/myactivity/common/sample.vue'
      export default {
            data() {
                  return {
                        tackclass: [{
                                    name: "首页",
                                    href: "/home",
                                    imgs: require("../../assets/myactivity/3.png"),
                              },
                              {
                                    name: "上课",
                                    href: "/attend",
                                    imgs: require("../../assets/myactivity/4.png"),
                              },
                              {
                                    name: "活动",
                                    href: "/attend",
                                    imgs: require("../../assets/myactivity/1.png"),
                              },
                              {
                                    name: "作品集",
                                    href: "/taskh",
                                    imgs: require("../../assets/myactivity/2.png"),
                              },
                        ],
                        category: 'works'
                  };
            },
            components: {
                  Sample
            },
            methods: {
                  goback() {
                        this.$router.go(-1);
                  },
                  site(value) {
                        if (value == '首页') {
                              this.$router.push({
                                    path: '/home',
                              });

                        } else if (value == '上课') {
                              this.$router.push({
                                    path: '/home',
                              });
                        } else if (value == '活动') {
                              this.$router.push({
                                    path: '/activity',
                              });
                        }

                  }
            },
      };
</script>
<style lang="less" scoped>
      @import "./less/sampleReels";
</style>